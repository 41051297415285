import { ApolloError } from '@apollo/client';
import {
  GetCompleteUserInstanceDetail,
  GetCompleteUserObjectInstanceDetail,
  GetQuickLiteUser,
  GetUserInstanceDetail,
  GetUserInstanceWithObjects,
  GetUsersForProcess
} from 'redux/database/User Instance API';
import {
  UpdateQuickLiteUser,
  UpdateUser
} from 'redux/database/User Instance API/mutations';
import { store } from 'redux/store';
import {
  CompleteObjectInstance,
  FieldInstance,
  CompleteUserInstance,
  UserInstance
} from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import {
  ICompleteUserInstanceDetail,
  ICreateRelationship,
  IDeleteRelationship,
  IGetQuickLiteUser,
  IGetRelationshipList,
  IGetRelationshipsRelatedToAssigned,
  IRelationshipInstance,
  IRelationshipListForKeyword,
  IUpdateQuickLiteUser,
  IUserForProcess,
  IUserForProcessRequest,
  IUserInstanceObjectData
} from './interface';
import { createNotification } from 'react-redux-notify';
import { errorNotif } from 'components/Notifications';
import { UpdateField } from 'redux/database/Field Instance API/mutations';
import { UpdateObject } from 'redux/database/Object Instance API/mutations';
import {
  GetListForKeywordRelationship,
  GetRelatedToAssignedRelationship,
  GetRelationshipList
} from 'redux/database/Relationship API';
import {
  CreateRelationship,
  DeleteRelationship
} from 'redux/database/Relationship API/mutations';
import { getDefinition } from '../user';
import { getCachedUserInstanceDetail } from './cachedRequests';

const { dispatch } = store;

const getUsersForProcess = async ({
  ProcessInstanceId,
  ProcessDefinitionId,
  UserDefinitionId,
  fetchQueries = false,
  action = 'USERSFORPROCESS',
  baseUrl
}: IUserForProcessRequest): Promise<IUserForProcess[] | undefined> => {
  try {
    const result = await GetUsersForProcess({
      ProcessInstanceId,
      ProcessDefinitionId,
      UserDefinitionId,
      fetchQueries,
      action,
      baseUrl
    });

    if (result instanceof ApolloError) {
      dispatchError(result);
      return undefined;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    handleNotification(errorMessage);

    return undefined;
  }
};

const getCompleteUserInstanceDetail = async ({
  baseUrl,
  UserInstanceId,
  action,
  fetchPolicy
}: {
  baseUrl: string;
  UserInstanceId: number;
  fetchPolicy?: boolean;
  action:
    | 'CompleteObjectInstanceList'
    | 'UserInstance'
    | 'CompleteUserInstanceDetail';
}): Promise<
  | Partial<CompleteUserInstance>
  | CompleteObjectInstance[]
  | UserInstance
  | undefined
> => {
  try {
    let result:
      | Partial<CompleteUserInstance>
      | CompleteObjectInstance[]
      | UserInstance
      | ApolloError;

    if (!UserInstanceId) {
      BugTracker.notify(
        new Error(
          'src/redux/actions/GraphQlActions/index/getCompleteUserInstanceDetail',
          new Error('Does Not Have UserInstanceId')
        )
      );

      return undefined;
    }

    switch (action) {
      case 'CompleteObjectInstanceList':
        result = await GetCompleteUserObjectInstanceDetail({
          baseUrl,
          UserInstanceId: parseInt(UserInstanceId.toString())
        });
        break;
      case 'UserInstance':
        result = await GetUserInstanceDetail({
          baseUrl,
          UserInstanceId: parseInt(UserInstanceId.toString())
        });
        break;

      case 'CompleteUserInstanceDetail':
        result = await GetCompleteUserInstanceDetail({
          baseUrl,
          UserInstanceId: parseInt(UserInstanceId.toString()),
          fetchPolicy
        });
        break;
    }

    if (result instanceof ApolloError) {
      dispatchError(result);
      return undefined;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    handleNotification(errorMessage);

    return undefined;
  }
};

type RelationshipActions =
  | IRelationshipListForKeyword
  | IDeleteRelationship
  | ICreateRelationship
  | IGetRelationshipsRelatedToAssigned
  | IGetRelationshipList;

/**
 * Retrieves or manipulates relationship data based on the specified action.
 * This function can list relationships, create or delete them, or fetch relationships related to specific assignments.
 *
 * @param {RelationshipActions} params - Parameters encapsulating action details and data required for various relationship operations.
 * @param {string} params.baseUrl - The base URL for the API endpoint.
 * @param {'LIST' | 'LIST_ALL' | 'LISTFORKEYWORD' | 'RELATEDTOASSIGNED' | 'CREATE' | 'DELETE'} params.action - The specific action to perform, determining the behavior of the function.
 * @param {number} [params.UserInstanceId] - The UserInstanceId involved in the relationship (required for 'LIST' and 'LISTFORKEYWORD').
 * @param {number} [params.UserDefinitionId] - The UserDefinitionId used for fetching a list of relationships (required for 'LIST').
 * @param {string} [params.keyword] - A keyword to filter relationships (required for 'LISTFORKEYWORD').
 * @param {number} [params.RelatedUserDefinitionId] - The UserDefinitionId related to the user assigned (required for 'RELATEDTOASSIGNED').
 * @param {number} [params.AssignedUserDefinitionId] - The UserDefinitionId assigned to handle the relationship (required for 'RELATEDTOASSIGNED').
 * @param {number} [params.ProcessInstanceId] - The ProcessInstanceId involved in the relationship (required for 'RELATEDTOASSIGNED').
 * @param {boolean} [params.fetchPolicy] - Will change the Query into "Fetch-Network" only instead of Cache first.
 * @param {IRelationshipData} [params.data] - The relationship data required to create a new relationship (required for 'CREATE').
 * @param {number} [params.PrimaryUserInstanceId] - The primary UserInstanceId for deletion of a relationship (required for 'DELETE').
 * @param {number} [params.RelatedUserInstanceId] - The related UserInstanceId for deletion of a relationship (required for 'DELETE').
 * @returns {Promise<UserInstance[] | string | IRelationshipInstance | undefined>} A promise that resolves with the result of the relationship operation or `undefined` in case of an error.
 */

const getRelationship = async (
  params: RelationshipActions
): Promise<UserInstance[] | string | IRelationshipInstance | undefined> => {
  try {
    let result: ApolloError | UserInstance[] | string | IRelationshipInstance;
    switch (params.action) {
      case 'LIST':
      case 'LIST_ALL':
        result = await GetRelationshipList({
          baseUrl: params.baseUrl,
          action: params.action,
          UserInstanceId: parseInt(params.UserInstanceId.toString()),
          UserDefinitionId: parseInt(params.UserDefinitionId.toString()),
          fetchPolicy: params.fetchPolicy
        });
        break;
      case 'LISTFORKEYWORD':
        result = await GetListForKeywordRelationship({
          baseUrl: params.baseUrl,
          action: params.action,
          UserInstanceId: params.UserInstanceId,
          keyword: params.keyword
        });
        break;
      case 'RELATEDTOASSIGNED':
        result = await GetRelatedToAssignedRelationship({
          baseUrl: params.baseUrl,
          action: params.action,
          RelatedUserDefinitionId: params.RelatedUserDefinitionId,
          AssignedUserDefinitionId: params.AssignedUserDefinitionId,
          ProcessInstanceId: params.ProcessInstanceId,
          fetchPolicy: params.fetchPolicy
        });
        break;
      case 'CREATE':
        result = await CreateRelationship({
          baseUrl: params.baseUrl,
          action: params.action,
          data: params.data,
          UserDefinitionId: params.UserDefinitionId,
          UserInstanceId: params.UserInstanceId
        });
        break;
      case 'DELETE':
        result = await DeleteRelationship({
          baseUrl: params.baseUrl,
          action: params.action,
          UserDefinitionId: params.UserDefinitionId,
          RelatedUserInstanceId: params.RelatedUserInstanceId,
          PrimaryUserInstanceId: params.PrimaryUserInstanceId
        });
    }

    if (result instanceof ApolloError) {
      dispatchError(result);
      return undefined;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    handleNotification(errorMessage);

    return undefined;
  }
};

/**
 * Updates Redux Store Action of "SET_ALL_LIST" and returns new "User List".
 * @param {string} baseUrl - The base URL for the hostname.
 * @param {number} UserInstanceId - The identifier of the UserInstance to fetch.
 */

const getUserInstanceDetail = async ({
  baseUrl,
  UserInstanceId,
  fetchPolicy
}: {
  baseUrl: string;
  UserInstanceId: number;
  fetchPolicy?: boolean;
}) => {
  let { userList } = store.getState().userAPI;

  let CompleteUserInstanceDetail = await getCachedUserInstanceDetail(
    parseInt(UserInstanceId.toString()),
    userList
  );

  //? If fetchPolicy is true, then fetch the data from the server, only happens for Redflag Import
  if (fetchPolicy) {
    CompleteUserInstanceDetail = CompleteUserInstanceDetail =
      (await getCompleteUserInstanceDetail({
        baseUrl,
        UserInstanceId: parseInt(UserInstanceId.toString()),
        action: 'CompleteUserInstanceDetail',
        fetchPolicy
      })) as ICompleteUserInstanceDetail;
  } else {
    if (!CompleteUserInstanceDetail) {
      CompleteUserInstanceDetail = (await getCompleteUserInstanceDetail({
        baseUrl,
        UserInstanceId: parseInt(UserInstanceId.toString()),
        action: 'CompleteUserInstanceDetail'
      })) as ICompleteUserInstanceDetail;
    }
  }

  if (CompleteUserInstanceDetail && userList) {
    const { CompleteObjectInstanceList, UserInstance } =
      CompleteUserInstanceDetail;

    const { Id, UserDefinitionId } = UserInstance;
    const updatedUserList = JSON.parse(JSON.stringify(userList));

    if (updatedUserList.length > 0) {
      const currentUser = updatedUserList?.[UserDefinitionId]?.[
        UserDefinitionId
      ].find(
        (CompleteUserInstanceDetail: ICompleteUserInstanceDetail) =>
          CompleteUserInstanceDetail.UserInstance.Id === Id
      );

      if (currentUser) {
        currentUser.CompleteObjectInstanceList = CompleteObjectInstanceList;
        currentUser.UserInstance = UserInstance;
      } else {
        updatedUserList?.[UserDefinitionId]?.[UserDefinitionId]?.unshift(
          CompleteUserInstanceDetail
        );
      }

      const updatedUser = updatedUserList?.[UserDefinitionId]?.[
        UserDefinitionId
      ].find(
        (CompleteUserInstanceDetail: ICompleteUserInstanceDetail) =>
          CompleteUserInstanceDetail.UserInstance.Id === Id
      );

      updatedUser.CompleteObjectInstanceList = CompleteObjectInstanceList;
      dispatch({ type: 'SET_ALL_LIST', payload: updatedUserList });
      return updatedUserList;
    } else {
      updatedUserList[UserDefinitionId] = {
        [UserDefinitionId]: [CompleteUserInstanceDetail]
      };

      await getDefinition({
        token: undefined,
        action: 'define',
        UserDefinitionId:
          CompleteUserInstanceDetail.UserInstance.UserDefinitionId
      });

      dispatch({ type: 'SET_ALL_LIST', payload: updatedUserList });

      return updatedUserList;
    }
  }
};

/**
 * Fetches or updates a UserInstance based on the provided action.
 * @param {params} params- The parameters for fetching or updating the UserInstance.
 * @param {string} params.baseUrl - The base URL for the hostname.
 * @param {number} params.UserInstanceId - The UserInstanceId of the User to fetch (when action is 'GET').
 * @param {number} params.ProcessInstanceId - The ProcessInstanceId to update (when action is 'UPDATE').
 * @param {UserInstance} params.UserInstance - The data to update (when action is 'UPDATE').
 * @param {"GET" | "UPDATE"} - The action type ('GET' or 'UPDATE'), GET calls "GetQuickLiteUser" while UPDATE calls "UpdateQuickLiteUser".
 * @returns {Promise<{UserInstance: UserInstance, loading: boolean} | undefined>} A promise resolving to an object containing the `UserInstance` and loading state or `undefined` in case of an error.
 */

const getQuickLiteUser = async (
  params: IGetQuickLiteUser | IUpdateQuickLiteUser
): Promise<{ UserInstance: UserInstance; loading: boolean } | undefined> => {
  try {
    switch (params.action) {
      case 'GET': {
        if (!params.UserInstanceId) return undefined;
        const result = await GetQuickLiteUser({
          baseUrl: params.baseUrl,
          UserInstanceId: params.UserInstanceId
        });

        if (result instanceof ApolloError) {
          dispatchError(result);
          return undefined;
        }

        return { UserInstance: result.data, loading: result.loading };
      }
      case 'UPDATE': {
        const result = await UpdateQuickLiteUser({
          baseUrl: params.baseUrl,
          ProcessDefinitionId: params.ProcessDefinitionId,
          data: params.data
        });

        if (result instanceof ApolloError) {
          dispatchError(result);
          return undefined;
        }

        return { UserInstance: result.data, loading: result.loading };
      }
    }
  } catch (e) {
    BugTracker.notify(e);
    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    handleNotification(errorMessage);

    return undefined;
  }
};

/**
 * This is primarily used in the Custom GDPR step.
 * @param {string} baseUrl - The base URL for the hostname.
 * @param {number} ProcessInstanceId - The identifier of the ProcessInstance to fetch.
 * @param {IUserInstanceObjectData} data - The data to update.
 * @returns {Promise<{UserInstance: UserInstance, CompleteObjectInstanceList: CompleteObjectInstance[]`} | undefined>} A promise resolving to an object containing the `UserInstance` and `CompleteObjectInstanceList` or `undefined` in case of an error.
 */

const getUserInstanceWithObjects = async ({
  baseUrl,
  ProcessInstanceId,
  data
}: {
  baseUrl: string;
  ProcessInstanceId: number;
  data: IUserInstanceObjectData;
}): Promise<Partial<CompleteUserInstance>[] | undefined> => {
  try {
    const result = await GetUserInstanceWithObjects({
      baseUrl,
      ProcessInstanceId,
      data
    });

    if (result instanceof ApolloError) {
      dispatchError(result);
      return undefined;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);
    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    handleNotification(errorMessage);

    return undefined;
  }
};

/**
 * Updates userDetails and associated CompleteObjectInstances.
 * Previously known as `postData` mutation.
 * @param {String} baseUrl - The base URL for the Hostname.
 * @param {UserInstance: IUpdateUserInstance, CompleteObjectInstanceList: CompleteObjectInstance[]} data - The data object containing the a UserInstance & CompleteObjectInstanceList.
 * @param {number} ProcessInstanceId - The identifier of the ProcessInstanceId to update.
 * @returns {Promise<Partial<CompleteUserInstance> | undefined>} - A promise resolving to a UserInstance & CompleteObjectInstanceList or an undefined.
 */
const updateUser = async ({
  baseUrl,
  ProcessInstanceId,
  data: payload
}: {
  baseUrl: string;
  ProcessInstanceId: number;
  data: Partial<CompleteUserInstance>;
}): Promise<Partial<CompleteUserInstance> | undefined> => {
  try {
    const result = await UpdateUser({
      baseUrl,
      ProcessInstanceId,
      data: payload
    });

    if (result instanceof ApolloError) {
      dispatchError(result);
      return undefined;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);
    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    handleNotification(errorMessage);

    return undefined;
  }
};

/**
 * Updates an CompleteObjectInstanceList using the provided parameters.
 * @param {string} baseUrl - The base URL for the hostname.
 * @param {number} ProcessInstanceId - The identifier of the ProcessInstanceId to update.
 * @param {string} action - The action to perform.
 * @param {CompleteObjectInstance} data - The data object containing the CompleteObjectInstanceList information.
 * @returns {Promise<CompleteObjectInstance | undefined>} - A promise resolving to the updated CompleteObjectInstanceList or an Undefined.
 */
const updateCompleteObjectInstanceList = async ({
  baseUrl,
  ProcessInstanceId,
  action,
  data,
  fetchPolicy
}: {
  baseUrl: string;
  ProcessInstanceId: number;
  action: string;
  data: CompleteObjectInstance;
  fetchPolicy?: 'cache-first' | 'network-only' | 'no-cache';
}): Promise<CompleteObjectInstance | undefined> => {
  try {
    const result = await UpdateObject({
      baseUrl,
      ProcessInstanceId,
      action,
      data,
      fetchPolicy
    });

    if (result instanceof ApolloError) {
      dispatchError(result);
      return undefined;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);
    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    handleNotification(errorMessage);

    return undefined;
  }
};

/**
 * Updates a FieldInstance using the provided parameters.
 * @param {string} baseUrl - The base URL for the hostname.
 * @param {number} ProcessInstanceId - The identifier of the ProcessInstanceId to update.
 * @param {FieldInstance} data - The data object containing the updated FieldInstance information.
 * @returns {Promise<FieldInstance | undefined>} - A promise resolving to the updated FieldInstance or an Undefined.
 */
const updateFieldInstance = async ({
  baseUrl,
  ProcessInstanceId,
  data
}: {
  baseUrl: string;
  ProcessInstanceId: number;
  data: FieldInstance;
}): Promise<FieldInstance | undefined> => {
  try {
    const newFieldInstance: FieldInstance = {
      ...data,
      FieldValue: data.FieldValue.toString()
    };

    const result = await UpdateField({
      baseUrl,
      ProcessInstanceId,
      data: newFieldInstance
    });

    if (result instanceof ApolloError) {
      dispatchError(result);
      return undefined;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);
    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    handleNotification(errorMessage);

    return undefined;
  }
};

const shouldShowNotifications = () => {
  const allowedUrls = [
    'http://localhost:3000',
    'https://test.bips.tech',
    'https://staging-bips.netlify.app'
  ];

  return allowedUrls.includes(window.location.origin);
};

const handleNotification = (message: string) => {
  if (shouldShowNotifications()) {
    dispatch(createNotification(errorNotif(message)));
  }
};

export const dispatchError = (error: ApolloError) => {
  if (shouldShowNotifications()) {
    dispatch(createNotification(errorNotif(error.message)));
  }
};

export {
  getQuickLiteUser,
  getUserInstanceDetail,
  getUserInstanceWithObjects,
  getCompleteUserInstanceDetail,
  getUsersForProcess,
  getRelationship,
  updateUser,
  updateCompleteObjectInstanceList,
  updateFieldInstance
};
