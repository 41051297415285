import { createNotification } from 'react-redux-notify';
import { errorNotif, infoNotif } from 'components/Notifications';
import { store } from 'redux/store';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { BugTracker } from 'Utils/Bugtracker';
import { SET_COSNETICS_STATE } from 'redux/actions/types';

const { dispatch } = store;

interface DBQ {
  config: AxiosRequestConfig;
  url: string;
  method?: string;
}

const shouldShowNotifications = () => {
  const allowedUrls = [
    'http://localhost:3000',
    'https://test.bips.tech',
    'https://staging-bips.netlify.app'
  ];

  return allowedUrls.includes(window.location.origin);
};

const handleNotification = (notifFunc: Function, message: string) => {
  if (shouldShowNotifications()) {
    dispatch(createNotification(notifFunc(message)));
  }
};
/**
 * Database Queue
 * @param {object} config
 * @param {string} url
 * @param {string} method
 */
export const dbQueue = async ({ config, url, method }: DBQ) =>
  await processQ({ config, url, method });

const processQ = async ({ config, url, method }) => {
  let response = {} as AxiosResponse;
  const logOutStatusList = [401];

  // REQUEST INTERCEPTOR
  axios.interceptors.request.use(
    (config) => {
      config.headers.Auth = store.getState().user.auth.token;
      config.baseURL = store.getState().config.baseURL;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // RESPONSE INTERCEPTOR
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  try {
    const res = await axios(config);

    if (res?.status !== 200) {
      if (res.status && logOutStatusList.includes(res.status)) {
        dispatch({ type: SET_COSNETICS_STATE, payload: false });
      } else if (res && res.status === 404) {
        handleNotification(
          infoNotif,
          `${res?.statusText}: ${res?.headers?.error}`
        );
      } else {
        handleNotification(
          errorNotif,
          `${res?.statusText}: ${res?.headers?.error}`
        );
      }
    }

    return (response = res);
  } catch (error) {
    const e = error as AxiosError;

    if (e?.response?.status && logOutStatusList.includes(e?.response?.status)) {
      dispatch({ type: SET_COSNETICS_STATE, payload: false });
    } else if (e?.response?.status === 404) {
      handleNotification(infoNotif, `${e?.response?.headers?.error}`);
    } else {
      handleNotification(
        errorNotif,
        `${e?.response?.statusText}: ${e?.response?.headers?.error}`
      );
    }

    BugTracker.notify(e);
  }

  return response;
};
