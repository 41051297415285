import React, {
  useState,
  Fragment,
  useRef,
  useContext,
  useEffect
} from 'react';
import { useTypedSelector } from 'redux/reducers';
import compose from 'recompose/compose';
import isEqual from 'react-fast-compare';
import { DateType } from '@date-io/type';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  Typography
} from '@material-ui/core';
import { withStyles, useTheme, createTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { DatePicker } from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';
import { tableIcons } from '../Utils/tableIcons';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from './styles';
import { SnackBar } from '../SnackBar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import WarningIcon from '@material-ui/icons/Warning';
import { PercentageField, CurrencyField } from '../Utils/inputFields';
import SeasonalPaymentsDialog from '../SeasonalPaymentsTable/dialog';
import Additional_InputsDialog from '../Additional_Input';
import CashFlowChart from '../CashFlowChart';
import NetAdvance from '../NetAdvance';
import Commission from '../Commission';
import DocumentFee from '../DocumentFee';
import Balloon from '../Balloon';
import OutPutScreen from './output';
import { Calculation, Init_Config, Asset } from 'types/calculatorInterfaces';
import AdditionalInputs from '../Additional_InputMenu';
import { useCalculator } from './hooks/useCalculator';
import {
  Props,
  Extra,
  Tools,
  SaveLabels,
  FromValue,
  ForValue
} from './interfaces';
import { INIT_STATE, INIT_CONFIG } from '../Utils/calculations';
import { setINIT_PERIODS, mergeDuplicateAssets } from './functions';
import { ListAssetPriceImporter } from '../AssetPriceImporter/ListAssetPriceImporter';
import { useCalculatorStorage } from '../CalculationStore/hooks/useCalculatorStorage';
import { useDispatch } from 'react-redux';
import { SET_CALCULATION_ID } from 'redux/actions/types';
import { green, blue, red, orange } from '@material-ui/core/colors';
import { StepperContext } from 'components/Stepper/context';
import { handleCalculatorObject } from 'components/Fields/InlineTextField/functions/handleCalculatorObject';
import * as gtag from 'Utils/gtag';
import { Skeleton } from '@material-ui/lab';
import { useProcess } from 'hooks';
import { ParentCalculationStore } from '../CalculationStore';
import _ from 'lodash';
import InitialRentals from '../Initial_Rentals';
import { IRowData } from '../SeasonalPaymentsTable/newIndex';
import { useValidateCalculationName } from './hooks/useValidateCalculationName';
import { Info } from '@material-ui/icons';
import { IPurchasePriceProps } from '../AssetPriceImporter/interface';

export const calculatorTheme = createTheme({
  palette: {
    primary: {
      main: green.A700,
      light: green.A200
    },
    secondary: {
      main: blue.A400,
      light: blue.A200
    },
    error: {
      main: red.A400,
      light: red.A200
    },
    warning: {
      main: orange.A400,
      light: orange.A200
    }
  }
});

const Warning = () => {
  const theme = useTheme();

  return (
    <Grid
      item
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        marginLeft: theme.spacing(1)
      }}>
      <WarningIcon
        style={{
          color: theme.palette.warning.main,
          marginRight: 10
        }}
      />
    </Grid>
  );
};

const { Autosave, Standalone } = SaveLabels;

const CalculatorV2 = (props: Props) => {
  const { classes } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const user = useTypedSelector((s) => s.user.user);
  const solveLayoutView = useTypedSelector((s) => s.calculator.solveLayoutView);
  const { currentProcess } = useTypedSelector((s) => s.process);
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { calculation } = useTypedSelector((s) => s.calculator);

  const [state, setState] = useState<Calculation>(INIT_STATE);
  const [autosaveTempState, setAutosaveTempState] = useState<Calculation>();

  const [config, setConfig] = useState<Init_Config>(INIT_CONFIG);
  const [balloonExtras, setBalloonExtras] = useState<Extra[]>([]);
  const INIT_PERIODS = setINIT_PERIODS(state);
  const [loadingStates, setLoadingStates] = useState<{
    calculation: boolean;
    save: boolean;
  }>({ calculation: false, save: false });

  const [period, setPeriod] = useState<any>(INIT_PERIODS);
  const [rows, setRows] = useState<IRowData[]>([]);

  let [extras, setExtras] = useState<Extra[]>([]);

  const [zeroPayments, setZeroPayments] = useState<Extra[]>([]);
  const [fromValue, setFromValue] = useState(FromValue.FromYield);
  const [forValue, setForValue] = useState(ForValue.ForPayment);
  const { setQuotesRefreshing } = useContext(StepperContext);
  const { assets } = useProcess();

  const tools: Tools = {
    balloonExtras,
    config,
    extras,
    forValue,
    fromValue,
    period,
    props,
    setBalloonExtras,
    setConfig,
    setExtras,
    setForValue,
    setFromValue,
    setPeriod,
    setRows,
    rows,
    setState,
    setZeroPayments,
    state,
    zeroPayments
  };

  const {
    balloonRep,
    buildPagePDF,
    calculated,
    setCalculated,
    errorMessage,
    firstPaymentDate,
    handleChange,
    handleDateChange,
    handleForChange,
    handleFromChange,
    initLayout,
    isContractPlus1,
    loading,
    notASavedVersion,
    original,
    Output,
    setBalloonRep,
    setOutput,
    snackBar,
    setSnackBar,
    clearAll,
    submitForm,
    setGrossAdvance,
    grossAdvance
  } = useCalculator(tools);

  const { validateCalculationName } = useValidateCalculationName({
    setSnackBar
  });

  const setUpData = async () => {
    const { calculation, forValue, fromValue } = await initLayout();
    setForValue(forValue);
    setFromValue(fromValue);

    const mergedAssets = mergeDuplicateAssets(calculation.Assets as Asset[]);

    setState({
      ...calculation,
      Assets: mergedAssets
    });
  };

  const subSystemUser = user.SystemAccess <= 4;
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleIntroducerAccess = () => {
    if (user.SystemAccess === 4) return true;
    else return false;
  };

  const saveCalc = async () => {
    setLoadingStates({ ...loadingStates, save: true });

    const isGrossAdvance =
      state._NetAdvance.Vat_Payment === 'ADD_VAT_TO_NET' ||
      state._NetAdvance.Vat_Payment === 'DEFER_VAT';

    const newStateIsGross = {
      ...state,
      Amount: isGrossAdvance ? grossAdvance : state.Amount
    };

    await handleCalculatorObject({
      props,
      output: newStateIsGross,
      IntroducerAccess: handleIntroducerAccess()
    });
    setLoadingStates({ ...loadingStates, save: false });

    gtag.event({
      action: 'Saving Calculate',
      feature: 'CALCULATOR',
      message: 'User pressing save quote in webapp'
    });

    props.handleClose();
    setQuotesRefreshing(false);
  };

  useEffect(() => {
    if (state) props.setState?.(state);
  }, [state]);

  const normalizeObject = (obj: Calculation | null): Calculation => {
    if (!obj) return {} as Calculation;
    let normalizedObj: Calculation = { ...obj };

    const keysToNormalize = [
      'Yield',
      'APR',
      'FlatRate',
      'NominalFlatRate',
      'Margin'
    ];

    keysToNormalize.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (typeof value === 'number') {
          normalizedObj[key] = Math.round(value);
        } else if (typeof value === 'string' && !isNaN(parseFloat(value))) {
          normalizedObj[key] = Math.round(parseFloat(value));
        }
      }
    });

    Object.keys(normalizedObj).forEach((key) => {
      if (!keysToNormalize.includes(key)) {
        const value = normalizedObj[key];
        if (typeof value === 'number' && !Number.isInteger(value)) {
          normalizedObj[key] = parseFloat(value.toFixed(2));
        }
      }
    });

    return normalizedObj;
  };

  useEffect(() => {
    let newState: Calculation = normalizeObject(state);
    const newCalculation = normalizeObject(calculation);

    if (newState && newState._NetAdvance) {
      newState._NetAdvance = _.omit(newState._NetAdvance, 'isLoading');
    }

    const keysToRemove: string[] = [
      'ExtraPayments',
      'CashFlow',
      'OverridePayments',
      'Amount'
    ];

    const filteredNewState = _.omit(newState, keysToRemove);
    const filteredNewCalculation = _.omit(newCalculation, keysToRemove);

    // console.log({ filteredNewState, filteredNewCalculation });
    // console.log({
    //   test: deepDifference(filteredNewState, filteredNewCalculation)
    // });

    if (!_.isEqual(filteredNewState, filteredNewCalculation)) {
      setCalculated(false);
    }
  }, [state, Output]);

  // Help Debug.
  // const deepDifference = (object1, object2) => {
  //   return _.transform(object1, (result: any, value, key) => {
  //     if (!_.isEqual(value, object2[key])) {
  //       result[key] =
  //         _.isObject(value) && _.isObject(object2[key])
  //           ? deepDifference(value, object2[key])
  //           : value;
  //     }
  //   });
  // };

  React.useEffect(() => {
    setUpData();
  }, []);

  React.useEffect(() => {
    setState(calculation);
    setOutput(calculation);
    setIsOpen(true);
  }, [calculation]);

  const { updateCalculation, saveCalculation, getMyCalculations } =
    useCalculatorStorage({});

  const handleAutosaveLogic = async () => {
    const hasAutosave = await getMyCalculations();
    const isQuote = tools?.props?.QuoteId;
    const newState = {
      ...state,
      extras: tools.extras
    };

    if (
      hasAutosave[`${Autosave} ${isQuote ? isQuote : Standalone}`] === undefined
    ) {
      saveCalculation(
        newState,
        `${Autosave} ${isQuote ? isQuote : Standalone}`
      );
    } else {
      setSnackBar({
        open: true,
        message: '(Auto-Save) Success',
        variant: 'success'
      });

      updateCalculation(
        newState,
        `${Autosave} ${isQuote ? isQuote : Standalone}`
      );
    }
  };

  const autoSaveInterval = useRef<number | null>(null);

  React.useEffect(() => {
    autoSaveInterval.current = window.setInterval(() => {
      // Does State = AutosaveTemp? Has It Been Calculated?
      if (state !== autosaveTempState && !calculated) {
        setAutosaveTempState(state);
        handleAutosaveLogic();
      }
    }, 30000);

    return () => {
      if (autoSaveInterval.current !== null) {
        clearInterval(autoSaveInterval.current);
      }
    };
  }, [autosaveTempState, state, calculated]);

  let isLoanDeal = false;
  if (Object.keys(currentProcess).length !== 0) {
    const Title = currentProcess?.ProcessDefinition?.Title;
    isLoanDeal = Title.includes('Loan') || Title === 'Alternative-Finance';
  }

  let isLeaseDeal = false;
  if (state.FinanceProduct !== '') {
    const Title = state.FinanceProduct;
    isLeaseDeal = Title.startsWith('Lease');
  }

  React.useEffect(() => {
    if (isLoanDeal) {
      setState((prevState) => ({
        ...prevState,
        _NetAdvance: {
          ...prevState._NetAdvance,
          Purchase_VatRate: 0
        }
      }));
    }

    if (isLeaseDeal) {
      setState((prevState) => ({
        ...prevState,
        _Commission: {
          ...prevState._Commission,
          Broker_VatRate: 20
        },
        _NetAdvance: {
          ...prevState._NetAdvance,
          Purchase_VatRate: 20
        }
      }));
    }
  }, [isLoanDeal, isLeaseDeal]);

  //? This is for Calculation Name Checker For the Icon
  const isPlaceholder =
    !state.Title || state.Title === 'null' || state.Title === '';
  return (
    <>
      <ParentCalculationStore
        state={state}
        tools={tools}
        quoteId={tools?.props?.QuoteId}
        setState={setState}
      />

      <Paper className={classes.paper} variant="outlined">
        <Grid className={classes.solveContainer} container direction="column">
          <Grid item sm={12} xs={6}>
            <div className={classes.toggleContainer}>
              {!subSystemUser && (
                <FormControl style={{ width: 175, margin: 8 }}>
                  <InputLabel
                    style={{ color: theme.palette.primary.contrastText }}>
                    SOLVE FOR
                  </InputLabel>
                  <Select
                    data-cy="solve-for"
                    style={{ color: theme.palette.primary.contrastText }}
                    classes={{ icon: classes.select }}
                    onChange={(event: React.ChangeEvent<{ value: any }>) =>
                      handleForChange(event, event.target.value)
                    }
                    value={forValue}>
                    <MenuItem value={ForValue.ForPayment}>Payment</MenuItem>
                    <MenuItem value={ForValue.ForAmount}>Cost</MenuItem>
                    <MenuItem value={ForValue.ForCommission}>
                      Commission
                    </MenuItem>
                    <MenuItem
                      data-cy="solve-for-rates"
                      value={ForValue.ForRates}>
                      Rates
                    </MenuItem>
                    <MenuItem value="forBalloon">Balloon</MenuItem>
                  </Select>
                </FormControl>
              )}
              {forValue !== ForValue.ForRates && !subSystemUser && (
                <FormControl style={{ width: 175, margin: 8 }}>
                  <InputLabel
                    style={{ color: theme.palette.primary.contrastText }}>
                    SOLVE FROM
                  </InputLabel>
                  <Select
                    data-cy="select-solve-from"
                    style={{ color: theme.palette.primary.contrastText }}
                    classes={{ icon: classes.select }}
                    onChange={(event: React.ChangeEvent<{ value: any }>) =>
                      handleFromChange(event)
                    }
                    value={fromValue}>
                    {solveLayoutView.map((value, index) => {
                      if (value === FromValue.FromRate) {
                        return (
                          <MenuItem
                            data-cy="solved-margin"
                            key={index}
                            value={value}>
                            Margin
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem
                            data-cy="solve-from-list"
                            key={index}
                            value={value}>
                            {value === FromValue.FromAPR
                              ? 'APR'
                              : value
                                  .replace('from', '')
                                  .split(/(?=[A-Z])/)
                                  .join(' ')}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              )}

              {!tools?.props?.QuoteId && (
                <Grid item>
                  <TextField
                    style={{
                      width: 150,
                      margin: 8,
                      color: 'white'
                    }}
                    id="NOTES_ID"
                    margin="dense"
                    multiline
                    InputLabelProps={{
                      style: {
                        color: theme.palette.primary.contrastText
                      }
                    }}
                    InputProps={{
                      style: { color: 'white' },
                      startAdornment: !state.Notes && (
                        <InputAdornment position="start">
                          <Info
                            style={{
                              color: theme.palette.info.light
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Hidden Notes"
                    label="HIDDEN NOTES"
                    value={state.Notes}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange('Notes')(event)
                    }
                  />
                </Grid>
              )}

              {!tools?.props?.QuoteId && (
                <Grid item>
                  <TextField
                    style={{ width: 150, margin: 8, color: 'white' }}
                    id="QuoteID"
                    margin="dense"
                    multiline
                    InputLabelProps={{
                      style: { color: theme.palette.primary.contrastText }
                    }}
                    InputProps={{
                      style: { color: 'white' },
                      startAdornment: isPlaceholder && (
                        <InputAdornment position="start">
                          <Info
                            style={{
                              color: theme.palette.info.light
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Calculator Name"
                    label="CALCULATION NAME *"
                    value={state.Title === 'null' ? '' : state.Title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange('Title')(event)
                    }
                  />
                </Grid>
              )}

              {calculated && (
                <Button
                  onClick={() => buildPagePDF()}
                  color="primary"
                  variant="contained">
                  Funder Document
                  <div style={{ marginLeft: 8 }}>
                    <tableIcons.Export />
                  </div>
                </Button>
              )}
              {tools.props.QuoteId && assets.length > 0 && (
                <div
                  style={{
                    marginLeft: 8
                  }}>
                  <ListAssetPriceImporter
                    setSnackBar={setSnackBar}
                    state={state}
                    importPurchasePrice={({
                      type,
                      value,
                      asset
                    }: IPurchasePriceProps) => {
                      const customValue = {
                        value,
                        asset
                      };

                      handleChange('Asset')({
                        target: { value: customValue }
                      });
                    }}
                  />
                </div>
              )}
              <div style={{ flexGrow: 1 }} />
              {/* May bring this back at some point but leave out for now */}
              {/* {notASavedVersion && (
              <>
                <Button
                  color="secondary"
                  onClick={() => resetToSaved(props)}
                  variant="contained">
                  Return to saved
                </Button>
              </>
            )} */}
            </div>

            {notASavedVersion && !isEqual(state, original) && (
              <Alert severity="warning" variant="filled" style={{ margin: 8 }}>
                <AlertTitle>Unsaved changes</AlertTitle>
                <Typography>
                  If you are making changes to a calculation, be sure to click
                  the <b>CALCULATE</b> button. Once the calculation is made then
                  click the <b>SAVE</b> button.
                </Typography>
              </Alert>
            )}
          </Grid>
        </Grid>
        <Divider className={classes.spacer} />
        <Grid item xs={12}>
          {tools?.props?.QuoteId && Output && Output['Calculation'] && (
            <div style={{ marginLeft: 8, marginRight: 8 }}>
              <AdditionalInputs state={state} setState={setState} />
            </div>
          )}
        </Grid>

        <div className={classes.root}>
          <form>
            <Grid className={classes.form} container direction="row">
              <Grid item md={calculated ? 6 : 12}>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Grid alignItems="center" container spacing={1}>
                      <Grid item sm={6} xs={12}>
                        <NetAdvance
                          tools={tools}
                          currentProcess={currentProcess}
                          isLoan={isLoanDeal}
                          grossAdvance={grossAdvance}
                          setGrossAdvance={setGrossAdvance}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        {config.BalloonRepaymentValue.show ? (
                          <CurrencyField
                            InputLabelProps={undefined}
                            adornment={undefined}
                            calculate={submitForm}
                            classes={undefined}
                            disabled={undefined}
                            error={undefined}
                            name={'Repayment Value *'}
                            onBlur={undefined}
                            onChange={(e: { target: { value: number } }) => {
                              setBalloonRep(e.target.value);
                            }}
                            onClick={undefined}
                            onKeyUp={undefined}
                            required={undefined}
                            startAdornment={undefined}
                            value={balloonRep}
                          />
                        ) : (
                          <Grid container direction="row" alignItems="center">
                            {forValue !== ForValue.ForPayment && (
                              <>{state.MonthlyPayment <= 0 && <Warning />}</>
                            )}
                            <Grid
                              item
                              xs={forValue !== ForValue.ForPayment ? 11 : 12}>
                              <CurrencyField
                                InputLabelProps={undefined}
                                adornment={undefined}
                                calculate={submitForm}
                                classes={undefined}
                                disabled={
                                  config.MonthlyPayment.show ? false : true
                                }
                                error={undefined}
                                name={'Repayment Value'}
                                onBlur={undefined}
                                onChange={handleChange('MonthlyPayment')}
                                onClick={undefined}
                                onKeyUp={undefined}
                                required={
                                  forValue === ForValue.ForAmount ||
                                  forValue === ForValue.ForCommission ||
                                  forValue === ForValue.ForRates
                                }
                                startAdornment={undefined}
                                value={state.MonthlyPayment}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <InitialRentals tools={tools} />
                  </Grid>

                  {!subSystemUser && (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Fragment>
                          {config.Yield.show && (
                            <>
                              {state.Yield <= 0 && <Warning />}
                              <Grid item xs>
                                <PercentageField
                                  InputLabelProps={undefined}
                                  calculate={submitForm}
                                  classes={undefined}
                                  disabled={!config.Yield.show}
                                  name={'Yield'}
                                  onBlur={undefined}
                                  onChange={handleChange('Yield')}
                                  onClick={undefined}
                                  onKeyUp={undefined}
                                  required
                                  style={undefined}
                                  type={undefined}
                                  value={state.Yield}
                                />
                              </Grid>
                            </>
                          )}

                          {config.MarginRate.show && (
                            <>
                              {state.MarginRate <= 0 && <Warning />}
                              <Grid item xs>
                                <PercentageField
                                  InputLabelProps={undefined}
                                  calculate={submitForm}
                                  classes={undefined}
                                  disabled={!config.MarginRate.show}
                                  name={'Margin'}
                                  onBlur={undefined}
                                  onChange={handleChange('MarginRate')}
                                  onClick={undefined}
                                  onKeyUp={undefined}
                                  required
                                  style={undefined}
                                  type={undefined}
                                  value={state.MarginRate}
                                />
                              </Grid>
                            </>
                          )}

                          {config.APR.show && (
                            <>
                              {state.APR <= 0 && <Warning />}
                              <Grid item xs>
                                <PercentageField
                                  InputLabelProps={undefined}
                                  calculate={submitForm}
                                  classes={undefined}
                                  disabled={!config.APR.show}
                                  name={'APR'}
                                  onBlur={undefined}
                                  onChange={handleChange('APR')}
                                  onClick={undefined}
                                  onKeyUp={undefined}
                                  required
                                  style={undefined}
                                  type={undefined}
                                  value={state.APR}
                                />
                              </Grid>
                            </>
                          )}

                          {config.NominalFlatRate.show && (
                            <>
                              {state.NominalFlatRate <= 0 && <Warning />}
                              <Grid item xs>
                                <PercentageField
                                  InputLabelProps={undefined}
                                  calculate={submitForm}
                                  classes={undefined}
                                  disabled={!config.NominalFlatRate.show}
                                  name={'Nominal Flat Rate'}
                                  onBlur={undefined}
                                  onChange={handleChange('NominalFlatRate')}
                                  onClick={undefined}
                                  onKeyUp={undefined}
                                  required
                                  style={undefined}
                                  type={undefined}
                                  value={state.NominalFlatRate}
                                />
                              </Grid>
                            </>
                          )}

                          {config.FlatRate.show && (
                            <>
                              {state.FlatRate <= 0 && <Warning />}
                              <Grid item xs>
                                <PercentageField
                                  InputLabelProps={undefined}
                                  calculate={submitForm}
                                  classes={undefined}
                                  disabled={!config.FlatRate.show}
                                  name={'Flat Rate'}
                                  onBlur={undefined}
                                  onChange={handleChange('FlatRate')}
                                  onClick={undefined}
                                  onKeyUp={undefined}
                                  required
                                  style={undefined}
                                  type={undefined}
                                  value={state.FlatRate}
                                />
                              </Grid>
                            </>
                          )}
                        </Fragment>

                        <Grid item xs={6}>
                          <PercentageField
                            InputLabelProps={undefined}
                            calculate={submitForm}
                            classes={undefined}
                            disabled={undefined}
                            name={'Cost of funds'}
                            onBlur={undefined}
                            onChange={handleChange('FundingRate')}
                            onClick={undefined}
                            onKeyUp={undefined}
                            required={undefined}
                            style={undefined}
                            type={undefined}
                            value={state.FundingRate} // InputLabelProps={classes.label}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item style={{ width: '100%' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={mobile ? 12 : 6}>
                        <Grid container spacing={1}>
                          {!subSystemUser && (
                            <Grid item style={{ width: '100%' }}>
                              <Commission {...tools} />
                            </Grid>
                          )}

                          {config.StartDate.show && (
                            <Grid item style={{ width: '100%' }}>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <DatePicker
                                    disabled={!config.StartDate.show}
                                    fullWidth
                                    label="Contract Start"
                                    margin="dense"
                                    onChange={(date: DateType | null) => {
                                      if (date) {
                                        const newDate = new Date(
                                          date?.format()
                                        );
                                        handleDateChange('StartDate', newDate);
                                      }
                                    }}
                                    value={state.StartDate}
                                    variant="dialog"
                                  />
                                </Grid>

                                <Grid item xs={6}>
                                  <DatePicker
                                    disabled
                                    fullWidth
                                    label="First Payment"
                                    margin="dense"
                                    onChange={(date: DateType | null) => {
                                      throw new Error(
                                        'Function not implemented.'
                                      );
                                    }}
                                    value={firstPaymentDate(
                                      state.StartDate,
                                      state.PaymentFrequency
                                    )}
                                    variant="dialog"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}

                          <Grid item style={{ width: '100%' }}>
                            <Balloon {...tools} />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        style={{
                          paddingTop: mobile ? 0 : 10
                        }}
                        xs={mobile ? 12 : 6}>
                        {!subSystemUser && (
                          <Card
                            style={{
                              background: theme.palette.primary.light
                            }}>
                            <CardContent>
                              <Grid container direction="column" spacing={1}>
                                <Grid
                                  item
                                  style={{
                                    width: '100%'
                                  }}>
                                  <DocumentFee {...tools} />
                                </Grid>

                                <Grid item>
                                  <SeasonalPaymentsDialog tools={tools} />
                                </Grid>

                                {!isLoanDeal && (
                                  <Grid item>
                                    <Additional_InputsDialog tools={tools} />
                                  </Grid>
                                )}
                              </Grid>
                            </CardContent>
                          </Card>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid container spacing={2}>
                      {!subSystemUser && (
                        <Grid item xs={props.QuoteId ? 3 : 4}>
                          <Button
                            data-cy="calculate-btn"
                            fullWidth
                            color="primary"
                            variant="contained"
                            style={{
                              background: theme.palette.success.main
                            }}
                            onClick={submitForm}>
                            {loading ? (
                              <CircularProgress
                                style={{ color: 'white' }}
                                size={24}
                              />
                            ) : calculated ? (
                              'Recalculate'
                            ) : (
                              'Calculate'
                            )}
                          </Button>
                        </Grid>
                      )}

                      {/* STANDALONE CALCULATOR */}
                      {!props.QuoteId && (
                        <Grid item xs={4}>
                          <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            style={{
                              background: theme.palette.warning.main
                            }}
                            onClick={async () => {
                              try {
                                setLoadingStates({
                                  ...loadingStates,
                                  save: true
                                });
                                const extras = state?.extras?.length
                                  ? state.extras
                                  : null;

                                const newState = {
                                  ...state,
                                  extras: extras ? extras : tools.extras
                                };

                                const { isInvalid, existingCalculationId } =
                                  await validateCalculationName(newState.Title);

                                if (isInvalid) {
                                  setLoadingStates({
                                    ...loadingStates,
                                    save: false
                                  });
                                  return;
                                }

                                let id;
                                let updatedCalculations;
                                let message = '';

                                if (existingCalculationId) {
                                  await updateCalculation(
                                    newState,
                                    existingCalculationId
                                  );
                                  id = existingCalculationId;
                                  message = `UPDATED calculation with Name: ${newState.Title}`;
                                } else {
                                  id = (await saveCalculation(newState)).id;
                                  message = `SAVED Calculation with Name: ${newState.Title}`;
                                }

                                updatedCalculations = await getMyCalculations();

                                if (updatedCalculations) {
                                  dispatch({
                                    type: SET_CALCULATION_ID,
                                    payload: id
                                  });

                                  setSnackBar({
                                    open: !snackBar.open,
                                    message,
                                    variant: 'success'
                                  });
                                }
                              } catch (err) {
                                setSnackBar({
                                  open: !snackBar.open,
                                  message: 'Something went wrong.',
                                  variant: 'warning'
                                });
                              }

                              setLoadingStates({
                                ...loadingStates,
                                save: false
                              });
                            }}>
                            {loadingStates.save ? (
                              <CircularProgress
                                style={{ color: 'white' }}
                                size={24}
                              />
                            ) : (
                              'Save'
                            )}
                          </Button>
                        </Grid>
                      )}

                      {/* INTEGRATED CALCULATOR */}
                      {props.QuoteId && (
                        <Grid
                          item
                          xs={props.QuoteId ? (!subSystemUser ? 3 : 4) : 4}
                          style={{ width: '100%' }}>
                          <Button
                            data-cy="save-calculations-btn"
                            color="primary"
                            fullWidth
                            onClick={saveCalc}
                            variant="contained"
                            style={{
                              background: theme.palette.warning.main
                            }}
                            disabled={
                              subSystemUser && state.Amount > 0
                                ? state.FinanceProduct !== ''
                                  ? false
                                  : true
                                : calculated && state.FinanceProduct !== ''
                                ? false
                                : true
                            }>
                            {loadingStates.save ? (
                              <CircularProgress size={24} />
                            ) : (
                              'Save'
                            )}
                          </Button>
                        </Grid>
                      )}

                      {props.QuoteId && (
                        <Grid
                          item
                          xs={props.QuoteId ? (!subSystemUser ? 3 : 4) : 4}
                          style={{ width: '100%' }}>
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            style={{
                              background: theme.palette.error.main
                            }}
                            onClick={() => {
                              props.handleClose();
                              setQuotesRefreshing(false);
                            }}>
                            Cancel
                          </Button>
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={props.QuoteId ? (!subSystemUser ? 3 : 4) : 4}
                        style={{ width: '100%' }}>
                        <Button
                          data-cy="calculator-clear-btn"
                          onClick={clearAll}
                          variant="contained"
                          fullWidth
                          style={{
                            background: calculatorTheme.palette.secondary.main,
                            color: 'white'
                          }}>
                          Clear
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {Output &&
                calculated &&
                !subSystemUser &&
                Object.keys(Output).length > 0 && (
                  <OutPutScreen
                    tools={tools}
                    Output={Output}
                    balloonRep={balloonRep}
                    setBalloonRep={setBalloonRep}
                    classes={classes}
                    forValue={forValue}
                    loading={loading}
                  />
                )}
            </Grid>
            {errorMessage !== null && (
              <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
            )}

            {Output &&
              calculated &&
              Output['CashFlow'] &&
              Output['CashFlow'].length > 0 &&
              !subSystemUser && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: 14
                  }}>
                  <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Button
                      onClick={handleClick}
                      className={classes.commissionIndex}>
                      Hide Chart / Show Chart
                    </Button>
                  </div>
                  {isOpen ? <CashFlowChart data={Output['CashFlow']} /> : null}
                </div>
              )}
            <SnackBar
              message={snackBar.message}
              open={snackBar.open}
              toggle={() =>
                setSnackBar({
                  open: !snackBar.open,
                  message: '',
                  variant: 'success'
                })
              }
              variant={snackBar.variant}
            />
          </form>
        </div>
        <p
          style={{
            width: '100%',
            textAlign: 'right',
            fontSize: 8,
            color: theme.palette.grey[200],
            paddingRight: theme.spacing(2)
          }}>
          Calculator 2.0
        </p>
      </Paper>
    </>
  );
};

export default compose(withStyles(styles))(CalculatorV2);

{
  /* <Grid item>
                              <Button
                                color="primary"
                                // onClick={() => setOpenSettings(!openSettings)}
                                style={{ width: '100%' }}
                                variant="contained">
                                Settings
                                <SettingsIcon
                                  className={classes.rightIcon}
                                  fontSize="small"
                                />
                              </Button>
                            </Grid> */
}

// Slackoverflow Function to help find differences
// function findDifferences(obj1, obj2, path = '') {
//   let differences: any[] = [];

//   Object.keys(obj1).forEach((key) => {
//     const newPath = path ? `${path}.${key}` : key;

//     if (!_.isEqual(obj1[key], obj2[key])) {
//       if (
//         typeof obj1[key] === 'object' &&
//         obj1[key] !== null &&
//         typeof obj2[key] === 'object' &&
//         obj2[key] !== null
//       ) {
//         differences = differences.concat(
//           findDifferences(obj1[key], obj2[key], newPath)
//         );
//       } else {
//         differences.push(
//           `Path: ${newPath}, obj1: ${obj1[key]}, obj2: ${obj2[key]}`
//         );
//       }
//     }
//   });

//   return differences;
// }
