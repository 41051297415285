import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateObject,
  selectObjectInstance
} from 'redux/actions/objectUpdater';
import { DeleteObject } from 'redux/database';
import {
  CompleteObjectInstance,
  FieldInstance,
  FieldDefinition,
  ObjectDefinition,
  UserInstance
} from 'types/interfaces';
import { Calculation } from 'types/calculatorInterfaces';
import { useTypedSelector } from 'redux/reducers';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';
import { refreshOverViewData } from 'redux/actions/processes/getDealData';
import { store } from 'redux/store';
import { globalIds } from 'helpers/globalIdConfig';
import {
  LENDER_PROPOSAL_DECISION,
  LENDER_PROPOSAL_DECISION_STATUS
} from './useDeals/useRepeatableObject';
import { updateFieldInstance } from 'redux/actions/GraphQlActions';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import firebase from 'firebase';

interface Props {
  ObjectDefinition: ObjectDefinition;
  UserInstance: UserInstance;
  calculation?: Calculation;
  ProcessInstanceId: number;
  Fields: FieldDefinition[];
  ProcessStepSelectionType: string | undefined;
  isAssetObject?: boolean;
  onOpen?: () => void;
  context?: string;
}
interface MinimalObjectInstance {
  ObjectDefinitionId: number;
  Title: string;
}
interface MinimalFieldInstance {
  FieldDefinitionId: number;
  FieldValue: string;
}
interface SavedAsset {
  id: string;
  [key: string]: any;
}

export const useObject = (props: Props) => {
  const dispatch = useDispatch();
  const { token } = useTypedSelector((s) => s.user.auth);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const user = useTypedSelector((s) => s.user.user);
  const { isAssetObject, onOpen } = props;

  const currentDeal = useTypedSelector((s) => s.process.currentDeal);
  const currentStepCanCompleteList: any = useSelector<any>(
    (state) => state.process.currentStep.UserInstanceDictForCurrentStep
  );
  const [loading, setLoading] = React.useState(false);
  const [savedAsset, setSavedAsset] = React.useState<SavedAsset[]>([]);
  let { ObjectDefinition, Fields, ProcessInstanceId } = props;

  let UserInstance = Object.assign({}, props.UserInstance);

  if (
    UserInstance.SystemAccess >= 4 &&
    props.ProcessStepSelectionType === 'OnBehalfOf'
  ) {
    const obosUserDefinitionId = props.ObjectDefinition.UserDefinitionId;
    const obhoUserInstanceId = parseInt(
      Object.keys(currentStepCanCompleteList)[0]
    );
    UserInstance.Id = obhoUserInstanceId;
    UserInstance.UserDefinitionId = obosUserDefinitionId;
  }

  const upDateObject = async (object: CompleteObjectInstance) => {
    setLoading(true);
    await updateObject({ token, data: object, props });
    return setLoading(false);
  };

  const toggleDeclineValue = async (
    object: CompleteObjectInstance,
    selected: boolean
  ) => {
    setLoading(true);
    const findDeclineDefinition = Fields.find(
      (FieldDefinition: FieldDefinition) =>
        FieldDefinition.Title.includes('Decline')
    );

    if (!findDeclineDefinition) return;
    const FieldInstanceList = getFieldInstances(object);
    const findDeclineInstance: FieldInstance | undefined =
      FieldInstanceList.find(
        (FieldInstance: FieldInstance) =>
          FieldInstance.FieldDefinitionId === findDeclineDefinition.Id
      );

    if (!findDeclineInstance) return;
    const isFalse = findDeclineInstance.FieldValue.includes('false');
    const isEmpty = findDeclineInstance.FieldValue === '';

    if (selected && isFalse) return;
    findDeclineInstance.FieldValue = isFalse || isEmpty ? 'true' : 'false';

    await updateFieldInstance({
      baseUrl,
      data: findDeclineInstance,
      ProcessInstanceId
    });
    return setLoading(false);
  };

  const declineObject = async (object: CompleteObjectInstance) => {
    if (object.ObjectInstance.Selected) {
      await toggleDeclineValue(object, false);
      await selectObjectInstance({
        token,
        data: object,
        props,
        action: 'SELECT'
      });
    } else {
      await toggleDeclineValue(object, false);
    }
  };

  const selectObject = async (object: CompleteObjectInstance) => {
    setLoading(true);

    // await toggleDeclineValue(object, true);
    await selectObjectInstance({
      token,
      data: object,
      props,
      action: 'SELECT'
    });
    return setLoading(false);
  };

  const publishObject = async (object: any) => {
    setLoading(true);
    const newObject = JSON.parse(JSON.stringify(object));
    newObject.ObjectInstance.IsPublished = !object.ObjectInstance.IsPublished;
    newObject.FieldInstanceList = getFieldInstances(newObject);

    await updateObject({
      token,
      data: newObject,
      props
    });
    return setLoading(false);
  };

  const duplicateObject = async (object: CompleteObjectInstance) => {
    setLoading(true);
    const newObject: CompleteObjectInstance = JSON.parse(
      JSON.stringify(object)
    );

    const FieldInstanceList = getFieldInstances(newObject);
    FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
      FieldInstance.Id = 0;
    });

    // When Duplicating Proposal We want to Removed Lender Decision if it's been made
    const lenderProposalIds =
      globalIds.customer.lenderProposal.LenderProposalObjectDefinition;
    const lenderProposal = lenderProposalIds.includes(
      newObject.ObjectInstance.ObjectDefinitionId
    );

    if (lenderProposal) {
      FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
        const Id = LENDER_PROPOSAL_DECISION.includes(
          FieldInstance.FieldDefinitionId
        );

        if (Id) {
          FieldInstance.FieldValue = '';
        }
      });
    }

    // When Duplicating Quote we want to Removed Declined Decision if it's been made
    const quoteIds = globalIds.customer.quotes.QuotesObjectDefinition;
    const quote = quoteIds.includes(
      newObject.ObjectInstance.ObjectDefinitionId
    );

    if (quote) {
      const QUOTE_DECLINED_ID = [23591];
      FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
        const Id = QUOTE_DECLINED_ID.includes(FieldInstance.FieldDefinitionId);

        if (Id) {
          FieldInstance.FieldValue = '';
        }
      });
    }

    newObject.ObjectInstance.Id = 0;
    newObject.ObjectInstance.Selected = false;
    newObject.FieldInstanceDict = {};
    newObject.FieldInstanceList = FieldInstanceList;

    await updateObject({
      token,
      data: newObject,
      props
    });

    dispatch(
      createNotification(
        successNotif(
          `Successfully Duplicated ${newObject.ObjectInstance.Title} From: ${object.ObjectInstance.Title} - ${object.ObjectInstance.Id}`
        )
      )
    );
    return setLoading(false);
  };

  const deleteObject = async (Id: number) =>
    await DeleteObject({ ObjectInstanceId: Id });

  const saveMinimalObjectToFirestore = async (
    objectInstance: MinimalObjectInstance,
    fieldData: MinimalFieldInstance[]
  ) => {
    const categoryField = fieldData.find(
      (field) => field.FieldDefinitionId === 18308
    );
    const category = categoryField?.FieldValue || 'Unknown';
    const essentialData = {
      objectData: {
        ObjectDefinitionId: objectInstance.ObjectDefinitionId,
        Title: objectInstance.Title
      },
      fieldData: fieldData.map((field) => ({
        FieldDefinitionId: field.FieldDefinitionId,
        FieldValue: field.FieldValue
      })),
      category,
      lastSaved: firebase.firestore.FieldValue.serverTimestamp()
    };
    try {
      await firebase
        .firestore()
        .collection('userAccount')
        .doc(user.Id.toString())
        .collection('savedAsset')
        .add(essentialData);
      dispatch(createNotification(successNotif('Asset Saved Successfully')));
    } catch (e) {
      console.error('Error saving object to Firestore: ', e);
    }
  };

  const fetchSavedAssets = async () => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection('userAccount')
        .doc(user.Id.toString())
        .collection('savedAsset')
        .orderBy('lastSaved', 'desc')
        .get();

      const assets = snapshot.docs.map((doc) => {
        const data = doc.data();
        const categoryField = data.fieldData?.find(
          (field) => field.FieldDefinitionId === 18308
        );

        return {
          id: doc.id,
          ...data,
          category: categoryField?.FieldValue || 'Unknown'
        };
      });

      setSavedAsset(assets);
      return assets;
    } catch (error) {
      console.error('Error fetching saved assets from Firestore:', error);
      return [];
    }
  };

  const createNewObject = async () => {
    setLoading(true);
    try {
      const fetchedAssets = await fetchSavedAssets();
      if (fetchedAssets.length > 0 && isAssetObject) {
        if (onOpen) {
          onOpen();
        } else {
          await proceedToCreateNewObject();
        }
      } else {
        await proceedToCreateNewObject();
      }
    } catch (error) {
      console.error('Error in createNewObject:', error);
    } finally {
      setLoading(false);
    }
  };

  const createObjectFromSavedAsset = async (assetData: SavedAsset) => {
    setLoading(true);
    const { objectData, fieldData } = assetData;
    const { UserDefinitionId } = ObjectDefinition;
    const UserInstanceId = UserInstance.Id;
    const isIndependent = ObjectDefinition.ObjectProcessIndependent;
    const NewObject: CompleteObjectInstance = {
      ObjectInstance: {
        Id: 0,
        Title: '',
        ProcessInstanceId: 0,
        ObjectDefinitionId: 0,
        UserDefinitionId: 0,
        UserInstanceId: 0,
        IsPublished: true,
        Selected: false,
        ItemOrder: 0
      },
      FieldInstanceList: [],
      FieldInstanceDict: {}
    };
    const ObjectInstance = {
      Id: 0,
      ProcessInstanceId: isIndependent ? 0 : ProcessInstanceId,
      Title: objectData.Title || ObjectDefinition.Title,
      ObjectDefinitionId: objectData.ObjectDefinitionId || ObjectDefinition.Id,
      UserDefinitionId,
      UserInstanceId,
      IsPublished: true,
      Selected: false,
      ItemOrder: 0
    };
    NewObject['ObjectInstance'] = ObjectInstance;
    Fields.forEach((field: FieldDefinition) => {
      const fieldDataItem = fieldData.find(
        (fieldData: MinimalFieldInstance) =>
          fieldData.FieldDefinitionId === field.Id
      );

      let FieldValue = '';
      if (fieldDataItem) {
        FieldValue = fieldDataItem.FieldValue;
      } else {
        console.warn(
          `Field data not found for field with ID ${field.Id} in saved asset`
        );
      }

      if (props.isAssetObject && field.Title === 'VAT Rate' && !FieldValue) {
        FieldValue = '20';
      }
      const FieldInstance: FieldInstance = {
        Id: 0,
        Title: '',
        FieldDefinitionId: field.Id,
        ObjectDefinitionId: ObjectDefinition.Id,
        ObjectInstanceId: 0,
        UserDefinitionId,
        FieldValue,
        ProcessInstanceId: isIndependent ? 0 : ProcessInstanceId,
        UserInstanceId,
        IsPublished: true
      };
      NewObject['FieldInstanceList'].push(FieldInstance);
    });
    if (Object.values(NewObject).length > 0) {
      const response = await updateObject({
        token,
        data: NewObject,
        props
      });
      if (response && response.ObjectInstance) {
        setLoading(false);
      }
    }
  };

  const proceedToCreateNewObject = async () => {
    setLoading(true);
    const { UserDefinitionId } = ObjectDefinition;
    const UserInstanceId = UserInstance.Id;
    const isIndependent = ObjectDefinition.ObjectProcessIndependent;
    const NewObject: CompleteObjectInstance = {
      ObjectInstance: {
        Id: 0,
        Title: '',
        ProcessInstanceId: 0,
        ObjectDefinitionId: 0,
        UserDefinitionId: 0,
        UserInstanceId: 0,
        IsPublished: true,
        Selected: false,
        ItemOrder: 0
      },
      FieldInstanceList: [],
      FieldInstanceDict: {}
    };
    NewObject['FieldInstanceList'] = [];
    const ObjectInstance = {
      Id: 0,
      ProcessInstanceId: isIndependent ? 0 : ProcessInstanceId,
      Title: ObjectDefinition.Title,
      ObjectDefinitionId: ObjectDefinition.Id,
      UserDefinitionId,
      UserInstanceId,
      IsPublished: true,
      Selected: false,
      ItemOrder: 0
    };
    NewObject['ObjectInstance'] = ObjectInstance;
    Fields.forEach((field: FieldDefinition) => {
      let FieldValue = '';
      // Handle Asset Objects VAT RATE field edge case
      if (props.isAssetObject && field.Title === 'VAT Rate') {
        FieldValue = '20';
      }

      const FieldInstance: FieldInstance = {
        Id: 0,
        Title: '',
        FieldDefinitionId: field.Id,
        ObjectDefinitionId: ObjectDefinition.Id,
        ObjectInstanceId: 0, // REQUIRED
        UserDefinitionId,
        FieldValue,
        ProcessInstanceId: isIndependent ? 0 : ProcessInstanceId,
        UserInstanceId,
        IsPublished: true
      };
      NewObject['FieldInstanceList'].push(FieldInstance);
    });
    if (Object.values(NewObject).length > 0) {
      const response = await updateObject({
        token,
        data: NewObject,
        props
      });
      if (response && response.ObjectInstance) {
        setLoading(false);
      }
    }
  };
  return {
    createNewObject,
    deleteObject,
    duplicateObject,
    loading,
    publishObject,
    selectObject,
    upDateObject,
    declineObject,
    savedAsset,
    setSavedAsset,
    saveMinimalObjectToFirestore,
    proceedToCreateNewObject,
    createObjectFromSavedAsset,
    fetchSavedAssets
  };
};
