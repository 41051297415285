import { useState, useEffect, useContext } from 'react';
import { useProcess } from 'hooks';
import { Grid } from '@material-ui/core';
import { UserInstance, Rule } from 'types/interfaces';
import { ruleGroupFilter, ruleFilter } from '../functions';
import { EnhancedGridItem } from './EnhancedGridItem';
import { useRuleSchema } from 'hooks/useDeals/useRuleSchema';
import { getAuditLogs, IFirestoreAuditLog } from 'hooks/useDeals/useAuditLog';
import { useTypedSelector } from 'redux/reducers';
import { Thread } from '../../../interfaces';
import { StepperContext } from 'components/Stepper/context';
import { permissionFilter } from 'components/Stepper/components/Steps/ActiveStep/helper/permissionFilter';

export const RulePreventionChecker = ({
  handleRuleClick,
  thread,
  disabledStates
}: {
  handleRuleClick: (rule: Rule) => Promise<void>;
  thread: Thread;
  disabledStates: { [key: string]: any };
}) => {
  const { getRuleGroups, user } = useProcess();
  const { regulatedStatus } = useTypedSelector((s) => s.process);
  const { ruleGroups } = getRuleGroups();

  const subSystemUser = user.SystemAccess <= 4;
  const isBrokerOnBrokerTab = thread.type === 'broker.contact';

  const shouldShowRule = (rule: Rule, disabledState: any): boolean => {
    if (
      !disabledState ||
      (typeof disabledState === 'object' &&
        Object.keys(disabledState).length === 0)
    ) {
      return true;
    }

    //? Commented Because we can now use Suppliers In Message hub
    //if (rule.Id === 205) return false;

    if (!permissionFilter(rule.Description, user)) {
      return false;
    }

    const { isRegulated_Only_Required, isNon_Regulated_Only_Required } =
      disabledState?.overallStatus || {};

    const isRegulatedOnly = isRegulated_Only_Required && !regulatedStatus;
    const isNonRegulatedOnly = isNon_Regulated_Only_Required && regulatedStatus;
    const isRegulatedFlag = disabledState?.regulated && !regulatedStatus;
    const isNonRegulatedFlag = disabledState?.nonRegulated && regulatedStatus;

    return !(
      isRegulatedOnly ||
      isNonRegulatedOnly ||
      isRegulatedFlag ||
      isNonRegulatedFlag
    );
  };

  const getFilteredRules = () => {
    return Object.keys(ruleGroups)
      .filter((name) => ruleGroupFilter(name, thread))
      .filter((name) => !isBrokerOnBrokerTab)
      .flatMap((name) =>
        ruleGroups[name]
          .filter((rule) => ruleFilter(rule, thread))
          // Only include rules that pass the permission filter
          .filter((rule) => permissionFilter(rule.Description, user))
          // Only include rules that should be shown based on disabled state
          .filter((rule) => shouldShowRule(rule, disabledStates[rule.Id]))
      );
  };

  if (subSystemUser) return null;
  const filteredRules = getFilteredRules();
  return (
    <Grid container alignItems="center" justifyContent="center">
      {filteredRules.map((rule) => (
        <EnhancedGridItem
          key={rule.Id}
          rule={rule}
          handleRuleClick={handleRuleClick}
          disabledState={disabledStates[rule.Id]}
          regulatedStatus={regulatedStatus}
        />
      ))}
    </Grid>
  );
};

export default RulePreventionChecker;
