import React, { useContext } from 'react';
import ActionMenu from './components/ActionMenu';
import Menu from '@material-ui/core/Menu';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { StepperContext } from './context';
import { Button, Divider } from '@material-ui/core';
import { useProcess } from 'hooks';
import { useTypedSelector } from 'redux/reducers';
import { TransferDealOwnership } from './components/Dialog/TransferDealOwner';

export const DealSettings = () => {
  const { currentDeal } = useProcess();
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const stepper = useContext(StepperContext);
  const { user } = useTypedSelector((s) => s.user);
  const fiveOrAboveUserAccess = user.SystemAccess >= 5;

  let dealClosed;
  if (Object.values(currentDeal).length > 0) {
    dealClosed = currentDeal.ProcessInstance.ProcessStepDefinitionId < 1;
  }

  return (
    <>
      <Button
        data-cy="status-update-btn"
        onClick={handleClick}
        variant="contained"
        color="primary"
        style={{ margin: 1 }}
        fullWidth>
        Deal Status
        <DoubleArrowIcon />
      </Button>

      <Menu
        PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 10, width: '30ch' } }}
        anchorEl={anchorEl}
        id="long-menu"
        keepMounted
        onClose={handleClose}
        open={open}>
        {!dealClosed && fiveOrAboveUserAccess && (
          <>
            <ActionMenu type="Deal Paid Out" handleCloseMenu={handleClose} />
            <ActionMenu
              type="Deal Not Taken Up"
              handleCloseMenu={handleClose}
            />
            <ActionMenu type="Decline Deal" handleCloseMenu={handleClose} />
          </>
        )}

        <Divider />
        {/* <MenuItem
          onClick={() => {
            stepper.duplicate();
            return handleClose();
          }}>
          <ListItemIcon>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
          <Typography color="primary">Copy Deal</Typography>
        </MenuItem> */}
        <ActionMenu type="Deal Configuration" handleCloseMenu={handleClose} />
        <TransferDealOwnership
          closeDeal={stepper.close}
          handleCloseMenu={handleClose}
          isSummary={false}
        />
        {dealClosed && (
          <ActionMenu type="Re-Activate Deal" handleCloseMenu={handleClose} />
        )}
      </Menu>
    </>
  );
};
