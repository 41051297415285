import { useContext, useState } from 'react';
import { Button } from '@material-ui/core';
import _ from 'lodash';

import InlineTextField from 'components/Fields/InlineTextField';
import FieldStatusIcon from '../../SingleObject/components/FieldStatusIcon';
import { pickFieldsToShow } from 'components/Stepper/functions/hideshowFields';
import { FieldDefinition, FieldInstance } from 'types/interfaces';
import SummaryDialog from './SummaryDialog';
import { useStyles } from '../styles';
import { useProcess } from 'hooks';
import { ContentTitle } from './ContentTitle';
import { theme } from 'theme';
import DetailsDialog from '../../helper/DetailsDialog';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { StepperContext } from 'components/Stepper/context';

const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
};

const Summary = (props) => {
  const forceUpdate = useForceUpdate();
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const { currentDeal, user: UserInstance } = useProcess();
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [openViewer, setOpenViewer] = useState<{
    open: boolean;
    FieldDefinition: FieldDefinition;
  }>({
    open: false,
    FieldDefinition: {} as FieldDefinition
  });
  const { ObjectInstance: CompleteObjectInstance } = props;
  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);

  const supplier: FieldDefinition[] = props.FieldDefinitionList.filter(
    (el: FieldDefinition) => el.Title.includes('Supplier')
  );

  const supplierField: FieldDefinition[] = pickFieldsToShow({
    FieldDefinitionList: supplier,
    CompleteObjectInstance
  });

  const dealCondition = isDealClosed || isDealTransferred;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ContentTitle
        FieldDefinitionList={props.FieldDefinitionList}
        FieldInstanceList={FieldInstanceList}
      />
      <br />
      <Button
        data-cy="open-summary-btn"
        color="primary"
        onClick={() => setOpen(!open)}
        variant="contained"
        fullWidth>
        Edit
      </Button>
      {UserInstance.SystemAccess > 4 &&
        supplierField &&
        supplierField.map((FieldDefinition: FieldDefinition, idx: number) => (
          <div key={idx}>
            {FieldInstanceList.map(
              (FieldInstance: FieldInstance, idx: number) => {
                if (FieldInstance.FieldDefinitionId === FieldDefinition.Id) {
                  return (
                    <div className={classes.paper} key={idx}>
                      <div style={{ display: 'flex' }}>
                        <FieldStatusIcon
                          FieldDefinition={FieldDefinition}
                          FieldInstance={FieldInstance}
                          Required={FieldDefinition.FieldRequired}
                        />
                        <p>{FieldDefinition.Title}</p>
                      </div>

                      <hr className={classes.hr} />

                      <div className={classes.inlinetext}>
                        <InlineTextField
                          readOnly={dealCondition}
                          FieldDefinition={FieldDefinition}
                          FieldInstance={FieldInstance}
                          ObjectDefinition={props.ObjectDefinition}
                          ObjectInstance={CompleteObjectInstance.ObjectInstance}
                          CompleteObjectInstance={CompleteObjectInstance}
                          ProcessInstance={currentDeal.ProcessInstance}
                          ProcessStepSelectionType={
                            props.stepdefdict.ProcessStepDefinition
                              .ProcessStepSelectionType
                          }
                          UserInstance={UserInstance}
                          fields={supplierField}
                          type={FieldDefinition.FieldType}
                          value={FieldInstance?.FieldValue}
                          refresh={forceUpdate}
                          parentsIsFormComponent
                        />
                      </div>
                      {FieldDefinition.FieldDescription === 'Viewer' &&
                        FieldInstance &&
                        FieldInstance?.FieldValue !== '' && (
                          <div style={{ paddingTop: theme.spacing(1) }}>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                setOpenViewer({
                                  ...openViewer,
                                  open: true,
                                  FieldDefinition: FieldDefinition
                                })
                              }>
                              View Details
                            </Button>
                          </div>
                        )}
                    </div>
                  );
                }
              }
            )}
          </div>
        ))}
      <DetailsDialog
        setOpen={setOpenViewer}
        open={openViewer}
        CompleteObjectInstance={CompleteObjectInstance}
      />
      <SummaryDialog
        open={open}
        setOpen={setOpen}
        FieldDefinitionList={props.FieldDefinitionList}
        FieldInstanceList={FieldInstanceList}
        ObjectDefinition={props.ObjectDefinition}
        CompleteObjectInstance={CompleteObjectInstance}
      />
    </div>
  );
};

export default Summary;
