import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, DialogTitle, lighten } from '@material-ui/core';

const SpecialObjectOverview = ({
  children,
  Icon,
  title,
  actionBtn,
  fullScreen,
  type,
  syncProcessInstance
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles({ type });
  // const fullScreen = !['Documents'].includes(title);
  const fullWidth = ['Message Hub', 'File Storage'].includes(title);

  return (
    <>
      <div
        className={classes.root}
        onClick={async () => {
          if (title === 'Message Hub') {
            setLoading(true);
            syncProcessInstance && (await syncProcessInstance());

            setLoading(false);
            setOpen(true);
          } else {
            setOpen(true);
          }
        }}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <>
            <Icon className={classes.icon} />
            <Typography className={classes.text}>{title}</Typography>
          </>
        )}
      </div>

      <Dialog
        scroll={'paper'}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        maxWidth="lg"
        onClose={() => setOpen(false)}
        open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogActions style={{ position: 'absolute', right: 0 }}>
          {actionBtn?.name ? (
            <ButtonGroup variant="outlined">
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpen(false)}>
                <CloseIcon />
              </Button>
            </ButtonGroup>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpen(false)}>
              <CloseIcon />
            </Button>
          )}
        </DialogActions>
        <DialogContent
          className={classes.dialog}
          style={!fullScreen ? { minHeight: 250, minWidth: 500 } : {}}>
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SpecialObjectOverview;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: (props: {
        type: 'success' | 'warning' | 'error' | 'info' | undefined;
      }) => {
        if (props.type === 'success') {
          return '#66CDAA';
        } else if (props.type === 'warning') {
          return '#FFB347';
        }
      },
      border: (props: {
        type: 'success' | 'warning' | 'error' | 'info' | undefined;
      }) => {
        const color = (() => {
          switch (props.type) {
            case 'warning':
              return theme.palette.warning.main;
            default:
              return theme.palette.grey[300];
          }
        })();
        return `1px solid ${color}`;
      },

      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      transition: 'all 0.3s ease-in-out',
      cursor: 'pointer',

      '& > p': {
        color: '#F8F8F8'
      },
      '& > svg': {
        color: '#F8F8F8'
      }
    },
    icon: { fontSize: 20, color: theme.palette.primary.main, marginRight: 8 },
    text: { color: theme.palette.primary.main },
    dialog: { padding: theme.spacing(2) }
  })
);

interface Props {
  type?: 'success' | 'warning' | 'error' | 'info';
  fullScreen: boolean;
  children: any;
  Icon?: any;
  title: string;
  actionBtn?: {
    name?: string;
    action?: () => void;
  };
  syncProcessInstance?: () => Promise<boolean>;
}
