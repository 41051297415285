import React, { useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Extra, Tools } from '../Calculator/interfaces';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';

import { useCalculator } from '../Calculator/hooks/useCalculator';
import SeasonalPayments, { IRowData } from './newIndex';
import {
  checkForOverlappingMonths,
  formatOverridePaymentsToRows,
  transformDataForExtras
} from './functions';
import { SnackBar } from '../SnackBar';
import { Alert, AlertTitle } from '@material-ui/lab';
import { PaymentFrequency } from 'types/calculatorInterfaces';
import moment from 'moment';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const ScrollDialog = ({ tools }: { tools: Tools }) => {
  const { customPaymentStructure, setSnackBar, snackBar } =
    useCalculator(tools);
  const { setExtras, rows, setState, state, setRows, extras, props } = tools;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    let overlap = 0;
    if (state.PaymentFrequency === PaymentFrequency.Monthly) {
      const overlapError = checkForOverlappingMonths(rows);
      if (overlapError) overlap = overlapError;
    }

    if (overlap > 0) {
      setSnackBar({
        open: true,
        message: `Warning: Overlapping periods detected in row ${overlap}. Please review and adjust accordingly.`,
        variant: 'warning'
      });

      return;
    }

    //? They can add Seasonal without amounts
    // const missingAmountError = checkForMissingAmounts(rows);
    // if (missingAmountError) {
    //   setSnackBar({
    //     open: true,
    //     message: `Warning: Missing amount in row ${missingAmountError}. Please fill out all amount fields.`,
    //     variant: 'warning'
    //   });

    //   return;
    // }

    const transformedData = transformDataForExtras(
      rows,
      state.StartDate,
      state
    );

    if (transformedData.length > 0) {
      setExtras(transformedData);

      // We no longer force the Seasonal Payment on Funding Profile
      // let term = 0;
      // rows.forEach((row: IRowData) => {
      //   if (row && row.PaymentFrequency) {
      //     const getPaymentFrequency = row.PaymentFrequency.toString();
      //     const getMonths = getMonthsMultiplier(getPaymentFrequency);
      //     const getNumber = parseInt(row.Number as string);

      //     if (!isNaN(getNumber)) {
      //       term += getMonths * getNumber;
      //     }
      //   }
      // });

      // setState({ ...state, FundingProfile: term });
    }
    setState({ ...state, OverridePayments: transformedData });
    setOpen(false);
  };

  useEffect(() => {
    // Check state first
    const hasStateExtras = state && state.extras && state.extras.length > 0;
    const hasStateOverridePayments =
      state?.OverridePayments?.length > 0 &&
      state.OverridePayments.every(
        (payment) => 'IsMonth' in payment && payment.IsMonth === true
      );

    // Check props as fallback
    const propsOverrides = props?.calculation?.OverridePayments;
    const hasPropsOverridePayments = Boolean(
      propsOverrides?.some((payment) => payment.IsMonth === true)
    );

    if (
      open &&
      (extras.length > 0 || hasStateExtras || hasStateOverridePayments)
    ) {
      const stateExtras = (state?.extras || []).filter(
        (payment) => payment.IsMonth === true
      );

      const stateOverrides = (state?.OverridePayments || []).filter(
        (payment) => payment.IsMonth === true
      );

      const monthlyPropsOverrides = (
        props?.calculation?.OverridePayments || []
      ).filter((payment) => payment.IsMonth === true);

      const combinedPayments = [
        ...stateExtras,
        ...extras,
        ...stateOverrides,
        ...(stateOverrides.length === 0 ? monthlyPropsOverrides : [])
      ];

      const uniquePaymentsSet = new Set<string>();
      const uniqueCombinedPayments = combinedPayments.filter((payment) => {
        if (payment.isDeferral) return false;

        const paymentKey = `${payment.StartPeriod}-${payment.EndPeriod}-${payment.Amount}`;
        if (uniquePaymentsSet.has(paymentKey)) {
          console.warn(`Duplicate payment found: ${paymentKey}`);
          return false;
        } else {
          uniquePaymentsSet.add(paymentKey);
          return true;
        }
      });

      const baseRows = formatOverridePaymentsToRows(
        uniqueCombinedPayments,
        state?.StartDate || props?.calculation?.StartDate,
        state.PaymentFrequency
      );

      const processedRows = baseRows.map((row) => {
        if (row.Number && row.StartPeriod) {
          const number = parseInt(row.Number.toString());
          let totalMonths = number;

          switch (state.PaymentFrequency) {
            case PaymentFrequency.Annually:
              totalMonths = number * 12;
              break;
            case PaymentFrequency.SemiAnnually:
              totalMonths = number * 6;
              break;
            case PaymentFrequency.Quarterly:
              totalMonths = number * 3;
              break;
            case PaymentFrequency.Monthly:
              totalMonths = number;
              break;
          }

          const startDate = moment(row.StartPeriod);
          const endDate = moment(startDate)
            .add(totalMonths - 1, 'months')
            .endOf('month');
          return {
            ...row,
            EndPeriod: endDate.format('YYYY-MM-DD')
          };
        }
        return row;
      });

      setRows(processedRows);
    }
  }, [open]);

  return (
    <div>
      <SnackBar
        message={snackBar.message}
        open={snackBar.open}
        toggle={() =>
          setSnackBar({
            open: !snackBar.open,
            message: '',
            variant: 'warning'
          })
        }
        variant={snackBar.variant}
      />

      <Button
        color="primary"
        onClick={async () => {
          // await customPaymentStructure();
          setOpen(!open);
        }}
        style={{ width: '100%' }}
        variant="contained">
        Seasonal Payments
      </Button>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={open}>
        <DialogTitle onClose={handleClose}>Seasonal Payments</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <AlertTitle>
              Please specify the number of months during which you would like
              the payments to be processed
            </AlertTitle>
            <Typography variant="h6" color="textSecondary">
              This will allow us to set up your payment schedule according to
              the specific months you choose, ensuring that payments are only
              made during those selected months.
            </Typography>
          </Alert>
          <br />
          <SeasonalPayments tools={tools} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ScrollDialog;

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// // interface Props {
// //   balloonExtras: Extra[];
// //   classes: any;
// //   customPaymentStructure: () => Promise<{
// //     balloonExtras: Extra[];
// //     zeroPayments: Extra[];
// //   }>;
// //   extras: Extra[];
// //   isOpen: boolean;
// //   setOpenExtraPayments: Dispatch<SetStateAction<boolean>>;
// //   period: any;
// //   setExtras: Dispatch<SetStateAction<Extra[]>>;
// //   setSnackBar: Dispatch<
// //     SetStateAction<{ open: boolean; variant: string; message: string }>
// //   >;
// //   state: Calculation;
// //   title: string;
// //   toggle: () => void;
// //   zeroPayments: Extra[];
// // }
