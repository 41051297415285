import { useContext, useState } from 'react';
import {
  Tooltip,
  Checkbox,
  IconButton,
  Button,
  ButtonGroup,
  CircularProgress
} from '@material-ui/core';
import { BiHide, BiShow } from 'react-icons/bi';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Check } from '@material-ui/icons';
import DeleteObjectButton from '../../components/DeleteObjectButton';
import { IProps } from '../interfaces';
import { CompleteObjectInstance, FieldInstance } from 'types/interfaces';
import { theme } from 'theme';
import { QuoteObjectContext } from '../context/QuoteObjectContext';
import { QuoteCreationContext } from '../context/QuoteCreationContext';
import { useProcess } from 'hooks';
import { useTypedSelector } from 'redux/reducers';
import { getSysDocFileUrl } from 'helpers/documentRetrieval';
import { useDispatch } from 'react-redux';
import { createNotification } from 'react-redux-notify';
import { errorNotif } from 'components/Notifications';

const RenderButtons = ({
  isDeclined,
  object,
  selected,
  props
}: {
  isDeclined: boolean;
  object: CompleteObjectInstance;
  selected: boolean;
  props: IProps;
}) => {
  const dispatch = useDispatch();
  const {
    downloadObject,
    publishObject,
    deleteObjectAndRefresh,
    selectObject,
    duplicateObject
  } = useContext(QuoteObjectContext);
  const { landingpage, prostepdefid, currentOverView, currentDeal } =
    useProcess();
  const { processInstanceFields } = useTypedSelector((s) => s.process);

  const { canAddMore, newlyCreatedQuotes } = useContext(QuoteCreationContext);
  const { user } = useTypedSelector((s) => s.user);
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false);

  const { display } = props;
  const IsPublished = object.ObjectInstance.IsPublished;

  const isEmpty = !!newlyCreatedQuotes.find(
    (quote: CompleteObjectInstance) =>
      quote.ObjectInstance.Id === object.ObjectInstance.Id
  );

  const getAndGoToFile = async (object: CompleteObjectInstance) => {
    let localCompleteObjectInstance;
    const getCurrentlySelectedQuote: CompleteObjectInstance | undefined =
      Object.values(currentDeal.CompleteObjectInstanceDict).find(
        (quote: CompleteObjectInstance) => quote.ObjectInstance.Selected
      );

    if (getCurrentlySelectedQuote) {
      if (
        getCurrentlySelectedQuote.ObjectInstance.Id !== object.ObjectInstance.Id
      ) {
        localCompleteObjectInstance = getCurrentlySelectedQuote;
      }
    }

    if (!selected) await selectObject(object);

    const _inst: FieldInstance[] =
      processInstanceFields[currentOverView.ProcessInstanceId];

    const zeroField: FieldInstance[] = _inst?.filter((el) => el.Id === 0);
    const findQuote: FieldInstance | undefined = zeroField.find(
      (FieldInstance: FieldInstance) => FieldInstance.Title.includes('Quote')
    );

    if (!findQuote)
      return dispatch(
        createNotification(errorNotif(`Failed To Load Document`))
      );

    try {
      if (findQuote) {
        const config = {
          ProcessInstanceId: currentOverView.ProcessInstanceId,
          ProcessStepDefinitionId: prostepdefid,
          DocumentStepDefinitionId: parseInt(findQuote.FieldValue),
          action: 'GetPDF'
        };

        const res = await getSysDocFileUrl(config);
        if (res) window.open(res);
      }
    } catch (e) {
      dispatch(createNotification(errorNotif(`Failed To Load Document ${e}`)));
      return;
    }

    if (!selected) await selectObject(object);
    if (localCompleteObjectInstance) {
      await selectObject(localCompleteObjectInstance);
    }
  };

  return (
    <ButtonGroup variant="text">
      {display === 'systemView' && (
        <Tooltip title="Hide/Show quote">
          <Checkbox
            icon={<BiHide />}
            checkedIcon={<BiShow />}
            checked={IsPublished}
            onChange={async () => await publishObject(object)}
          />
        </Tooltip>
      )}
      {!isEmpty && display === 'systemView' && (
        <Tooltip title="Download Full Quote">
          <Button
            style={{ color: 'grey' }}
            onClick={async () => {
              setLoadingDocument(true);
              await getAndGoToFile(object);
              setLoadingDocument(false);
            }}>
            {loadingDocument ? (
              <CircularProgress size={20} />
            ) : (
              <CloudDownloadIcon style={{ marginRight: 4 }} />
            )}
          </Button>
        </Tooltip>
      )}

      {user.SystemAccess >= 5 &&
        !isDeclined &&
        display === 'systemView' &&
        !isEmpty && (
          <Tooltip title="Select Quote">
            <Button
              onClick={async () => {
                await selectObject(object);
              }}
              style={{
                color: selected ? theme.palette.success.main : 'grey',
                backgroundColor: selected ? theme.palette.success.light : ''
              }}>
              <Check
                fontSize={selected ? 'large' : 'default'}
                style={{ marginRight: 4 }}
              />
            </Button>
          </Tooltip>
        )}

      {user.SystemAccess >= 5 && isDeclined && !isEmpty && landingpage && (
        <Tooltip title="Select quote">
          <Button
            onClick={async () => await selectObject(object)}
            style={{
              color: selected ? theme.palette.success.main : 'grey',
              backgroundColor: selected
                ? theme.palette.success.light
                : theme.palette.grey[50],
              border: `${selected ? '2px' : '1px'} solid ${
                selected ? theme.palette.success.main : theme.palette.grey[300]
              }`
            }}>
            <Check
              fontSize={selected ? 'large' : 'default'}
              style={{ marginRight: 4 }}
            />
          </Button>
        </Tooltip>
      )}

      {display === 'systemView' && !landingpage && !isEmpty && (
        <Tooltip title="Duplicate quote">
          <IconButton
            style={{ alignSelf: 'flex-end', color: 'grey' }}
            onClick={async () => await duplicateObject(object)}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      )}

      {display === 'systemView' && (
        <Tooltip title="Delete quote">
          <div>
            <DeleteObjectButton
              object={object}
              deleteObject={deleteObjectAndRefresh}
              title={`Quote ${object.ObjectInstance.Id}`}
            />
          </div>
        </Tooltip>
      )}
    </ButtonGroup>
  );
};

export default RenderButtons;

// Allows User To Select Single Select Quote
// {props.display !== 'systemView' && (
//             <Tooltip title="Decline quote">
//               <Button
//                 onClick={() => declineObject(object)}
//                 style={{
//                   color: isDeclined ? theme.palette.error.main : 'grey',
//                   backgroundColor: isDeclined
//                     ? theme.palette.error.light
//                     : ''
//                 }}>
//                 <CloseIcon
//                   fontSize={isDeclined ? 'large' : 'default'}
//                   style={{ marginRight: 4 }}
//                 />
//               </Button>
//             </Tooltip>
//           )}
