import ConfirmationDocument from './DialogChecklistContent';
import { dialogConfigGroups } from '../consts';
import { IChecklistTypes, IUniqueRequiredInformation } from '../interface';

const RuleDialogConfig = ({
  ruleId,
  ProcessDefinitionId,
  allChecked,
  checklist,
  handleChecklistChange
}: {
  ruleId: number;
  allChecked: boolean;
  ProcessDefinitionId: number;
  checklist: IChecklistTypes;
  handleChecklistChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}): IUniqueRequiredInformation => {
  const configGroup = dialogConfigGroups.find((group) =>
    group.ids.includes(ruleId)
  );

  // Proposal Contract Isn't working for Prop Finance 1228
  const config = configGroup?.config;
  if (config) {
    return {
      ...config,
      additionalContent: config.showChecklist ? (
        <ConfirmationDocument
          ruleId={ruleId}
          allChecked={allChecked}
          checklist={checklist}
          handleChecklistChange={handleChecklistChange}
          showAssignedViewers={config.showAssignedViewers}
          additionalCheckboxes={config.checkboxes}
        />
      ) : undefined
    };
  }

  // Fallback case
  // If the dealflow is Alternative Finance (TOBA) then hide the fallback.
  if (ProcessDefinitionId === 548) {
    const noFallback = [1752, 1754, 1755, 1756, 1779, 1763, 1780, 1783, 1761];
    if (noFallback.includes(ruleId))
      return {
        isRequired: false,
        dialogTitle: "You're about to send this rule to the Landing Page User.",
        showChecklist: false,
        showAssignedViewers: false,
        additionalContent: (
          <ConfirmationDocument
            ruleId={ruleId}
            allChecked={allChecked}
            checklist={checklist}
            handleChecklistChange={handleChecklistChange}
            showAssignedViewers
          />
        )
      };
  }

  return {
    isRequired: true,
    dialogTitle: "You're about to send this rule to the Landing Page User.",
    showChecklist: false,
    showAssignedViewers: false,
    additionalContent: (
      <ConfirmationDocument
        ruleId={ruleId}
        allChecked={allChecked}
        checklist={checklist}
        handleChecklistChange={handleChecklistChange}
        showAssignedViewers
      />
    )
  };
};

export default RuleDialogConfig;
