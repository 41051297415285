import { withStyles, Theme, createStyles, Tabs, Tab } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { ProcessStepInstance, ProcessStepGroup } from 'types/interfaces';
import { StyledBadge } from './';
import { useTypedSelector } from 'redux/reducers';
import { IChildTabs } from './interfaces';
import { useProcess } from 'hooks/useProcess';
import {
  ConsumerDulyConfirmation,
  NonRegulatedDulyConfirmation
} from './ParentTabs';

const ChildTabsC = withStyles({
  root: {},
  indicator: { display: 'none' }
})(Tabs);

const ChildTabC = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      background: theme.palette.background.paper,
      '&:hover': { opacity: 1 },
      '&$selected': {},
      '&:focus': {}
    },
    selected: {
      color: 'white',
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
      fontWeight: theme.typography.fontWeightMedium
    }
  })
)(Tab);

export const ChildTabs = ({
  handleStepChange,
  stepGroupIndex,
  landingStep,
  activeStep,
  ProcessStepGroupDict,
  ProcessStepDefinitionSequence,
  steps
}: IChildTabs) => {
  const theme = useTheme();
  const { currentDeal, currentProcess } = useProcess();
  const landingPage = useTypedSelector((s) => s.utility.landingpage);
  const { regulatedStatus } = useTypedSelector((s) => s.process);

  const ProcessGroupList: ProcessStepGroup[] =
    Object.values(ProcessStepGroupDict);
  const index: ProcessStepGroup = ProcessGroupList[stepGroupIndex];
  const ProcessStepDefinitionIdList: number[] =
    index['ProcessStepDefinitionIdList'];

  if (landingPage) {
    return null;
  }

  return (
    <div style={{ padding: `0px 10px 10px` }}>
      <ChildTabsC
        style={{
          position: 'relative',
          zIndex: 1,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10
        }}
        // onChange={(e, v) => handleStepChange(v, stepGroupIndex)}
        scrollButtons="auto"
        value={landingStep ? landingStep : activeStep}
        variant="scrollable">
        {ProcessStepDefinitionIdList.map((index: number, idx: number) => {
          const stepDetails =
            currentProcess.CompleteProcessStepDefinitionDict[index];

          if (
            ConsumerDulyConfirmation.includes(
              stepDetails.ProcessStepDefinition.Id
            )
          ) {
            if (!regulatedStatus) return null;
          }

          if (
            NonRegulatedDulyConfirmation.includes(
              stepDetails.ProcessStepDefinition.Id
            )
          ) {
            if (regulatedStatus) return null;
          }

          const isMandatory =
            stepDetails.ProcessStepDefinition.IsMandatoryForGroup;

          if (!isMandatory) return null;

          // if we make the index be where the step is on the main list
          const actualIndex = ProcessStepDefinitionSequence?.findIndex(
            (i: number) => i === index
          );

          const step: any = Object.values(steps).find(
            (el: any) => el.ProcessStepDefinition.Id === index
          );

          if (!step.ProcessStepDefinition.IsPublished) return null; // Need to add this when step publishing is fixed
          const title = step.ProcessStepDefinition.Title;
          if (
            title === 'Customer Discovery' ||
            title === 'Fact Find Form' ||
            title === 'Information'
          )
            return null;

          // Filters list to steps
          const entries = currentDeal?.ProcessStepInstanceList?.filter(
            (el) => el.ProcessStepDefinitionId === index
          );

          const noOfEntries = entries?.length;
          let color: 'default' | 'primary' | 'secondary' | 'error' | undefined =
            'error';
          let coloured = theme.palette.primary.main;

          if (noOfEntries > 0) {
            const statusList = entries.map(
              (ent: ProcessStepInstance) => ent.Status
            );
            const minimum: number = Math.min(...statusList);
            switch (minimum) {
              case 0:
                color = 'error';
                coloured = theme.palette.error.main;
                break;
              case 1:
                color = 'secondary';
                coloured = theme.palette.warning.main;
                break;
              case 2:
                color = 'primary';
                coloured = theme.palette.success.main;
                break;
            }
          }

          const selected = actualIndex === activeStep;
          return (
            <ChildTabC
              key={actualIndex}
              // onClick={() => handleStepChange(idx, stepGroupIndex)}
              style={{
                width: 250,
                background: selected
                  ? `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`
                  : 'lightgrey',
                fontSize: 10,
                display: 'flex',
                color: selected ? 'white' : 'black'
              }}
              label={
                <StyledBadge badgeContent={noOfEntries} color={color} showZero>
                  {title}
                </StyledBadge>
              }
            />
          );
        })}
      </ChildTabsC>
    </div>
  );
};

// {isActiveDealStep && (
// <div
//  style={{
//    position: 'absolute',
//    borderTop: `50px solid ${coloured}`,
//    borderBottom: '50px solid transparent',
//    borderLeft: '50px solid transparent',
//    right: 0,
//    top: 0
//  }}>
//</ChildTabsC>  <span>*</span>
// </div>
// )}
