import { IRelationshipTable } from 'components/Table/Relationships/components/Tabs/interfaces';
import { UserInstance, CompleteUserInstance } from 'types/interfaces';

// Permission Logic for updating a user
const isCompleteUserInstance = (
  user: UserInstance | CompleteUserInstance
): user is CompleteUserInstance => {
  return (
    (user as CompleteUserInstance)?.UserInstance?.Id !== undefined &&
    (user as CompleteUserInstance)?.UserInstance?.Id !== undefined
  );
};

export const determineIfUserCanEdit = ({
  loggedInUser,
  targetUser
}: {
  loggedInUser: UserInstance | CompleteUserInstance;
  targetUser: UserInstance | CompleteUserInstance;
}) => {
  let loggedInUserInstance = isCompleteUserInstance(loggedInUser)
    ? loggedInUser.UserInstance
    : loggedInUser;
  let targetUserInstance = isCompleteUserInstance(targetUser)
    ? targetUser.UserInstance
    : targetUser;
  let IsLandingPageUser = loggedInUserInstance.SystemAccess === 0;
  // let IsSystemUser = loggedInUserInstance.SystemAccess === 5;
  let IsAdmin = loggedInUserInstance.SystemAccess >= 10;
  let IsOwner =
    targetUserInstance?.OwnerUserInstanceId == loggedInUserInstance.Id;
  let IsSelf = targetUserInstance?.Id == loggedInUserInstance.Id;
  let IsUserPlus = loggedInUserInstance.SystemAccess === 6;

  // if landingpage user then cannot edit.
  if (IsLandingPageUser) return false;
  // if user is admin then can edit.
  if (IsAdmin) return true;
  // if user is not admin but is owner then can edit.
  if (IsOwner) return true;
  // if user is self then can edit.
  if (IsSelf) return true;
  // if user is UserPlus then they can edit others
  if (IsUserPlus) return true;
  // if user is not admin and not owner then cannot edit.
  if (!IsAdmin && !IsOwner && !IsUserPlus) return false;
  // return true or false
}; // END

export const determineIfUpdateRelationships = ({
  rowData
}: {
  rowData: IRelationshipTable;
}): boolean => {
  // Requested By Shamila to have System Owners able to Read & Write
  // const isMine = rowData.OwnerUserInstanceId === loggedInUser.Id;
  // const isAdmin = loggedInUser.SystemAccess >= 10;
  // const canEdit = isMine || (isAdmin && ownerIsNotSystem);
  const ownerIsNotSystem = rowData.OwnerUserInstanceId > 0;

  let canEdit;
  if (rowData.UserDefinitionId === 21) {
    if (ownerIsNotSystem) canEdit = false;
    else canEdit = true;
  } else canEdit = true;

  return canEdit;
};
